import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { loginUser } from "../../store/Auth/actions";
import { useSelector, useDispatch } from "react-redux";
import FieldValidation from "../../components/Common/FieldValidation";
import { apiError } from "../../store/actions";

function Login(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apiError(""));
  }, []);
  $("#userMobile").on("input", function() {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "")
    }
    if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
      this.value = this.value.replace(/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    }
  })
  const [phone_number, set_phone_number] = useState("");
  const [password, set_password] = useState("");
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(10);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 500);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  const handleSubmit = (e) => {
    setRunTimer((t) => !t)
    e.preventDefault();
    const data = {
      phone_number: phone_number,
      password: password,
    };
    dispatch(loginUser(data, props.history));
  };

  const formErrors = useSelector((state) => state?.CommonState);
  const removeError = (key) => {
    let e = _.cloneDeep(formErrors);
    delete e[key];
    dispatch(apiError(e));
  };

  return (
    <>
      <div className="BLM-pageHeader">
        <div className="BLM-pageHeader-title">Login</div>
      </div>
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister BLM-login">
            <div className="BLM-content">
              <form onSubmit={handleSubmit}>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Phone Number:</div>
                  <div
                    className="BLM-form-control-group"
                    id="errorMessageClass"
                  >
                    <div className="BLM-form-addon">+251</div>
                    <div className="BLM-form-control">
                      <input
                        type="tel"
                        id="userMobile"
                        placeholder="XXXXXXXXX"
                        maxLength={9}
                        required
                        onChange={(e) => {
                          if ([undefined, null, ""].includes(phone_number)) {
                            removeError("phone_number");
                          }
                          set_phone_number(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.phone_number ? (
                      <FieldValidation
                        errorMessage={formErrors?.phone_number}
                      />
                    ) : null}
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter 6 digit PIN:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="errorMessagePassClass"
                  >
                    <input
                      type="password"
                      id="regPass"
                      placeholder="XXXXXX"
                      onkeyup="return numberMobile(event)"
                      onkeypress="return enterUserName(event)"
                      maxLength={6}
                      required
                      onChange={(e) => {
                        if ([undefined, null, ""].includes(password)) {
                          removeError("password");
                        }
                        set_password(e.target.value);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg">
                    {formErrors?.password ? (
                      <FieldValidation errorMessage={formErrors?.password} />
                    ) : null}
                    {formErrors?.message ? (
                      <FieldValidation errorMessage={formErrors?.message} />
                    ) : null}
                  </div>
                </div>
                <div className="space-5" />
                {
                  runTimer ?
                      <div className="input-group-append">

                        <span className="input-group-text text-prepend-box" style={{justifyContent: "center"}}><span
                            className="spinner-grow spinner-grow-sm"></span
                        ></span>
                      </div>
                      :
                      <button
                          className="btn BLM-btnSecondary active BLM-btnLarge"
                          id="disableLoginButtonClick"
                          onclick="return gotoLogin();"
                          type="submit"
                      >
                        <span className="BLM-btnTxt">Login</span>
                      </button>
                }
              </form>
              <div className="space-5" />
              <Link to="/forgot-pin">
                <div className="BLM-forgotPin">
                  <span>Forgot PIN ?</span>
                </div>
              </Link>
              <div className="space-10" />
              <div className="BLM-loginRegister-navigation">
                Not Registered?
                <Link to="/register">
                  <span className="BLM-txtHightlight">Join Now!</span>
                </Link>
              </div>
              <div className="space-10" />
              <div className="BLM-matchBoxSEO d-none">
                <div className="BLM-matchBoxSEO-header">
                  <h1>Login to your Sakabet Account</h1>
                </div>
                <div className="BLM-matchBoxSEO-content">
                  Log in to your sports betting account here. For premium sports
                  betting that is safe and secure, Sakabet is the place to be!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
