import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Common/PageHeader";
import { Link } from "react-router-dom";
import FieldValidation from "../../components/Common/FieldValidation";
import { axiosApi } from "../../helpers/repository";
import MessageBox from "../../components/Common/MessageBox";
import { get } from "lodash";

function TransactionHistory() {
  const [key, set_key] = useState("All");
  const [transaction, setTransaction] = useState();
  const [date, setDate] = useState(null);
  const [loadMoreMessage, setLoadMoreMessage] = useState("Load More Data");
  const [trigger, setTrigger] = useState(1);
  const [next_page_url, set_next_page_url] = useState(null);

  useEffect(() => {
    call();
  }, [trigger]);

  useEffect(() => {
    call();
  }, []);
  const call = () => {
    var id = JSON.parse(localStorage.getItem("user"))?.user?.id;
    axiosApi({
      method: "get",
      url: `/client/get-tranasaction/${id}?type=${key}&date=${date}&perPage=10&page=${trigger}`,
    })
      .then(function (response) {
        set_next_page_url(response?.data?.data?.next_page_url);
        if (
          trigger != 1 &&
          transaction &&
          response?.data?.data?.data?.length != 0
        ) {
          setTransaction(transaction.concat(response?.data?.data?.data));
        } else {
          setTransaction(response?.data?.data?.data);
        }
      })
      .catch(function (response) {});
  };
  return (
    <>
      <PageHeader title={"Transaction History"} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister">
            <div className="BLM-content">
              <form>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Date:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regConfirmErrorMessageClass"
                  >
                    <input
                      type="date"
                      className="form-control"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                        setTrigger(1);
                      }}
                    />
                  </div>
                  <div className="BLM-form-errorMsg"></div>
                  <div className="BLM-form-errorMsg d-none">
                    <span id="regLengthErrorMessage" />
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Filters:</div>
                  <div
                    className="BLM-form-control BLM-form-control-pin"
                    id="regConfirmErrorMessageClass"
                  >
                    <div className="BLM-select">
                      <select
                        id="ddn_leagues"
                        onChange={(e) => {
                          set_key(e.target.value);
                          setTrigger(1);
                        }}
                      >
                        <option value="All">All</option>
                        <option value="deposit">Deposit</option>
                        <option value="withdraw">Withdrawal</option>
                        {/* <option value="lodgement">Lodgement</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="BLM-form-errorMsg"></div>
                  <div className="BLM-form-errorMsg d-none">
                    <span id="regLengthErrorMessage" />
                  </div>
                </div>

                <button
                  onClick={call}
                  className="btn BLM-btn BLM-btnSecondary active BLM-btnLarge"
                  id="disableRegisterButtonClick"
                  type="button"
                >
                  <span className="BLM-btnTxt">Submit</span>
                </button>
              </form>
              <div className="space-10" />

              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Type</th>
                    <th scope="col">Value</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {transaction &&
                    transaction.map((item, index) => {
                      return (
                        <>
                          <tr key={index + 1}>
                            <th scope="row">
                              {new Date(
                                Date.parse(item?.created_at)
                              ).toLocaleDateString()}{" "}
                              {new Date(
                                Date.parse(item?.created_at)
                              ).toLocaleTimeString()}
                            </th>
                            <td>{item?.payment_type}</td>
                            <td>{item?.amount}</td>
                            <td>{item?.status}</td>
                          </tr>
                        </>
                      );
                    })}
                </tbody>
              </table>
              <div className="space-10" />
              {next_page_url && (
                <div
                  onClick={() => {
                    setTrigger(trigger + 1);
                  }}
                >
                  <MessageBox data={loadMoreMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TransactionHistory;
