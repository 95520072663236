import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/Common/PageHeader";
import { setActiveTab } from "../../store/actions";
import { showMessage } from "../../components/Common/Toaster";
import { callDepositeAmount } from "../../helpers/repository";
import { currency } from "../../helpers/constants";
import { getfreebetAmount } from "../../components/Common/Utils/helpers";
import ReactModal from "react-modal";
function Deposits() {
  const dispatch = useDispatch();
  const [num, setNumber] = useState(20);
  const [chapaParam, setchapaParam] = useState(false);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [openModel, setModelOpen] = useState(false);
  const [freebet, setfreebet] = useState(getfreebetAmount());
  useEffect(() => {
    dispatch(setActiveTab(""));
  }, []);
  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };

  useEffect(() => {
    // Get the current URL
    const url = new URL(window.location.href);
    // Get the value of the 'chapa' parameter
    const chapaParam = url.searchParams.get("chapa");
    setchapaParam(chapaParam);
  }, []);

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px!important",
      // backgroundColor: "#272727",
      color: "black",
      padding: "0px",
    },
  };

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);
  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const _handleModalOpen = (e) => {
    setModelOpen(true);
  };
  const _handleSubmitDeposite = (e) => {
    setModelOpen(false);
    setRunTimer((t) => !t);
    let data = {};
    data.amount = num;
    data.user_id = JSON.parse(localStorage.getItem("user"))?.user?.id;
    data.currency = currency;
    data.mobile_prefix = "251";
    callDepositeAmount(data)
      .then((res) => {
        window.location.href = res?.data?.url;
      })
      .catch((e) => {
        showMessage("error", e?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);
  return (
    <>
      <PageHeader title={"Deposit"} showBack={false} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="space-3"></div>
          {chapaParam ? (
            <div className="row">
              <h6
                className="text-center
            "
              >
                Please check your payment status in the transaction history
              </h6>
            </div>
          ) : null}

          <div className="space-5"></div>
          {!chapaParam && freebet != 0 ? (
            <div className="row">
              <h6
                className="text-center
            "
                style={{ color: "#00ad73" }}
              >
                You have a Signup Freebet Bonus you can use to play a Sports bet
              </h6>
            </div>
          ) : null}
          <div className="space-5"></div>
          <div className="row">
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "20" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("20");
                }}
              >
                <span className="BLM-btnTxt">20</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "50" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("50");
                }}
              >
                <span className="BLM-btnTxt">50</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "100" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("100");
                }}
              >
                <span className="BLM-btnTxt">100</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "500" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("500");
                }}
              >
                <span className="BLM-btnTxt">500</span>
              </button>
            </div>
          </div>
          <div className="space-10"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="BLM-form-group">
                <div className="BLM-form-label">Enter Amount to Deposit:</div>
                <div
                  className="BLM-form-control BLM-form-control-pin"
                  id="errorMessagePassClass"
                >
                  <input
                    type="text"
                    id="userPass"
                    onkeyup="return numberMobile(event)"
                    onkeypress="return enterUserName(event)"
                    placeholder="XXXXX"
                    required
                    pattern="[0-9]*"
                    value={num}
                    onChange={handleChange}
                  />
                </div>
                <div className="BLM-form-errorMsg">
                  <span id="displayErrorMessage"></span>
                </div>
              </div>
            </div>
          </div>
          <div className="space-5"></div>
          <div className="row">
            <p>
              After clicking on "Deposit," please wait for the popup
              notification on your mobile phone. By selecting "Yes," you will be
              redirected to the Chapa Checkout Page.
            </p>
            {/* <li>
                After confirming the payment, you will be redirected back to the
                platform. Please check your payment status in the transaction
                history.
              </li> */}

            {/* After clicking on "Deposit", please wait for the popup on your
            mobile phone and confirm the payment by inputting your MPESA PIN.
            Kindly ensure you have enough balance in your MPESA account to
            complete the transaction. */}
          </div>

          <div className="space-5"></div>
          {runTimer ? (
            <button
              className="btn BLM-btnSuccess active BLM-btnLarge"
              id="disableLoginButtonClick"
            >
              <span className="spinner-grow spinner-grow-sm"></span>
            </button>
          ) : (
            <button
              className="btn BLM-btnSuccess active BLM-btnLarge"
              id="disableLoginButtonClick"
              onClick={() => {
                _handleModalOpen();
              }}
            >
              <span className="BLM-btnTxt">Deposit</span>
            </button>
          )}
          {
            <div className="row img_row">
              <ReactModal
                isOpen={openModel}
                style={customModalStyles}
                // className="modal-content-react"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title h4">DEPOSIT</div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setModelOpen(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p style={{ fontSize: "17px" }}>
                      Are you sure you want to deposit {num} amount?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        _handleSubmitDeposite();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        setModelOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ReactModal>
            </div>
          }
          {/* <div className="space-5"></div> */}

          {/* <div className="row">
            <div className="col-lg-5"></div>
            <div className="separator col-lg-2">OR</div>
            <div className="col-lg-5"></div>
          </div> */}
          {/* <div className="space-5"></div> */}

          {/* <div className="withdrawal-bottom-section">
            <div className="BLM-commonHeader">
              <b className="BLM-commonHeader-title">How to top up via MNO</b>
            </div>
          </div> */}
          {/* <div className="space-5"></div> */}
          {/* <div>
            <h3 className="BLM-common-mainTitle mb-3">Via Vodacom App</h3>
            <p>To top up your account</p>
            <ol>
              <li>
                Go to the Mpesa Menu, select Lipa na Mpesa and click Pay bill
              </li>
              <li>
                Enter 238844 as the business number and PrimeBetIn as the
                account number
              </li>
              <li>
                Enter the amount you wish to deposit then input your MPESA PIN.
              </li>
              <li>Click OK to confirm the transaction.</li>
            </ol>
            <div className="space-5"></div>
            <h3 className="BLM-common-mainTitle mb-3">Via Tigo Pesa</h3>
            <ol>
              <li>
                Go to the TigoPesa Menu, select Lipa na Tigo and click Pay bill
              </li>
              <li>
                Enter 238844 as the business number and PrimeBetIn as the
                account number
              </li>
              <li>
                Enter the amount you wish to deposit then input your TigoPesa
                PIN.
              </li>
              <li>Click OK to confirm the transaction.</li>
            </ol>
            <div className="space-5"></div>
            <h3 className="BLM-common-mainTitle mb-3">Via Airtel-Money</h3>
            <ol>
              <li>
                Go to the Airtel Money Menu, select Lipa na Airtel-Money and
                click Pay bill
              </li>
              <li>
                Enter 238844 as the business number and PrimeBetIn as the
                account number
              </li>
              <li>
                Enter the amount you wish to deposit then input your
                Airtel-Money PIN.
              </li>
              <li>Click OK to confirm the transaction.</li>
            </ol>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Deposits;
