import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Common/PageHeader";
import { Link } from "react-router-dom";
import FieldValidation from "../../components/Common/FieldValidation";
import { axiosApi } from "../../helpers/repository";
import MessageBox from "../../components/Common/MessageBox";
import { get } from "lodash";
import moment from "moment";

function BonusAwarded() {
  const [bonusData, setBonusData] = useState();

  const convertToDateTimeString = (date) => {
    const format = "DD/MM/YYYY HH:mm:ss";
    return moment(date).format(format);
  };

  useEffect(() => {
    call();
  }, []);

  const call = () => {
    var id = JSON.parse(localStorage.getItem("user"))?.user?.id;
    axiosApi({
      method: "get",
      url: `admin/get-promotion-reward/${id}`,
    })
      .then(function (response) {
        setBonusData(response?.data?.data);
      })
      .catch(function (error) {});
  };

  return (
    <>
      <PageHeader title={"Bonuses Awarded"} />
      <div className="BLM-commonPages">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-loginRegister">
            <div className="BLM-content">
              <div className="space-10" />
              <p style={{ fontSize: "20px", fontWeight: "500" }}>
                BONUSES AWARDED
              </p>
              {bonusData?.first_deposit &&
              bonusData?.first_deposit.length != 0 ? (
                <div className="table-responsive">
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date Added</th>
                        <th>Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-danger">
                        <td scope="row">
                          {convertToDateTimeString(
                            bonusData?.first_deposit[0].date_added
                          )}
                        </td>
                        <td>{bonusData?.first_deposit[0].type}</td>
                        <td>{bonusData?.first_deposit[0].amount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
              {bonusData?.LoyaltyPromo &&
              bonusData?.LoyaltyPromo.length != 0 ? (
                <div className="table-responsive" style={{ marginTop: "20px" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date Created</th>
                        <th>Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusData?.LoyaltyPromo?.map((loyaltyPromo, key) => (
                        <tr key={key} className="table-success">
                          <th scope="row">
                            {convertToDateTimeString(loyaltyPromo?.date_added)}
                          </th>
                          <td>{loyaltyPromo?.type}</td>
                          <td>{loyaltyPromo?.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}
              {bonusData?.Acca7 && bonusData?.Acca7.length != 0 ? (
                <div className="table-responsive" style={{ marginTop: "20px" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date Created</th>
                        <th>Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusData?.Acca7?.map((acca, key) => (
                        <tr key={key} className="table-success">
                          <th scope="row">
                            {convertToDateTimeString(acca?.date_added)}
                          </th>
                          <td>{acca?.type}</td>
                          <td>{acca?.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}

              {bonusData?.SignupFreebet &&
              bonusData?.SignupFreebet.length != 0 ? (
                <div className="table-responsive" style={{ marginTop: "20px" }}>
                  <table className="table mb-0">
                    <thead>
                      <tr>
                        <th>Date Added</th>
                        <th>Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bonusData?.SignupFreebet?.map((Signup, key) => (
                        <tr key={key} className="table-primary">
                          <th scope="row">
                            {convertToDateTimeString(Signup?.date_added)}
                          </th>
                          <td>{Signup?.type}</td>
                          <td>{Signup?.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                ""
              )}

              {/*<table className="table">*/}
              {/*    <thead>*/}
              {/*    <tr>*/}
              {/*        <th scope="col">Date</th>*/}
              {/*        <th scope="col">Type</th>*/}
              {/*        <th scope="col">Value</th>*/}
              {/*//         <th scope="col">Status</th>*/}
              {/*//     </tr>*/}
              {/*    </thead>*/}
              {/*    <tbody>*/}

              {/*    </tbody>*/}
              {/*</table>*/}
              <div className="space-10" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BonusAwarded;
