import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showSideBar } from "../../store/actions";
import Hamburger from "./Hamburger";
import SideMenuContent from "./SideMenuContent";

function LeftMenu(props) {
  const dispatch = useDispatch();
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;

  return (
    <>
      <div
        className="BLM-leftMenu-mask"
        id="BLM-leftMenu-mask"
        onClick={() => {
          dispatch(showSideBar(false));
        }}
      />
      <div className="BLM-sideMenu">
        <div className="BLM-sideMenu-main-content">
          <div className="BLM-sideMenu-container">
            <div
              className="BLM-language-container d-none"
              id="getLanguageDropdown"
            >
              <div className="BLM-label">Language:</div>
              <div className="BLM-select">
                <select id="ddn_languages" onChange="getLanguage()">
                  <option value="pt-PT">Portuguese</option>
                  <option value="en-US">English</option>
                </select>
              </div>
            </div>
            <div className="SB-tabs-boxed">
              <ul className="sidemenu-tablist row" id="Sidemenu_tabs">
                {!isLoggedIn && <li className={`col-10 active`}>Menu</li>}
                {isLoggedIn && (
                  <li className={`col-lg-10 col-md-10 col-10 active`}>
                    My Account
                  </li>
                )}

                <div
                  id="sidebar_trigger"
                  className=""
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    dispatch(showSideBar(false));
                  }}
                >
                  <button
                    type="button"
                    className="btn-close btn-close-white btn-cls-ham"
                    aria-label="Close"
                  ></button>
                  {/* <Hamburger /> */}
                </div>
              </ul>
            </div>
            <SideMenuContent isLoggedIn={isLoggedIn} />
          </div>
        </div>
      </div>
      <div className="BLM-rightMenu" id="betslipDisplay"></div>
      <div className="BLM-rightMenu-mask" />
    </>
  );
}

export default LeftMenu;
