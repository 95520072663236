import React, { useState, useEffect } from "react";
import TabMenu from "../../../components/Common/TabMenu";
import SportsDetailsLive from "./SportsDetailsLive";
import SportsDetailsTopLeague from "./SportsDetailsTopLeague";
import {
  getFlagEmoji,
  getSportName,
} from "./../../../components/Common/Utils/helpers";
import PageHeader from "../../../components/Common/PageHeader";
import { useSelector } from "react-redux";
// import MessageBox from "../../components/Common/MessageBox";
import MessageBox from "../../../components/Common/MessageBox";
import {
  getLiveFixturesBySportIdCall,
  getLiveSportCall,
  getLocationsBysports,
} from "../../../helpers/repository";

function SportsDetails(props) {
  const Sports = useSelector((s) => s.Sports?.Index?.data ?? []);
  const getId = () => {
    const urlParams = new URLSearchParams(props.location.search);
    let id = urlParams.get("id");
    return id;
  };
  const [loadingMessage, setLoadingMessage] = useState("");
  const [sportId, set_sport_id] = useState(getId());
  const [live_sports_count, set_live_sports_count] = useState(0);
  const [Locations, setLocations] = useState([]);

  useEffect(() => {
    setLoadingMessage("Loading Data...");
    if (sportId) {
      // getLiveSportCall(sportId).then((res) => {
      //   set_live_sports_count(res?.data?.data?.live);
      // });

      getLocationsBysports(sportId)
        .then((res) => {
          setLocations(res?.data?.data);
          // console.log(res?.data?.data);
        })
        .finally(() => {
          setLoadingMessage("");
        });
    }
  }, [sportId]);

  // const getleagueInfo = (location) => {
  //   // TODO: change league.sport_id to actual sport_id in league
  //   props.history.push(
  //     `/location-league-details?sport_id=${sportId}&id=${location.bb_id}`
  //   );
  //   //  console.log(location);
  // };

  const getleagueInfo = (league) => {
    // TODO: change league.sport_id to actual sport_id in league
    props.history.push(`/league-details?sport_id=${1}&id=${league.bb_id}`);
  };

  const activeTab = useSelector(
    (state) => state?.CommonState?.activeTab ?? "TopLeagues"
  );

  const [active_tab, set_active_tab] = useState("TopLeagues");
  // const tabs = [
  //   {
  //     id: "All",
  //     title: "All Countries",
  //     activeClass: active_tab == "All" ? "active" : "",
  //     tabHighlightText: "",
  //     tabHighlightId: "",
  //   },
  //   {
  //     id: "TopLeagues",
  //     title: "Top Leagues",
  //     activeClass: active_tab == "TopLeagues" ? "active" : "",
  //     tabHighlightText: "",
  //     tabHighlightId: "",
  //   },
  //   {
  //     id: "LiveNow",
  //     title: "Live Now",
  //     activeClass: active_tab == "LiveNow" ? "active" : "",
  //     tabHighlightText: `(${live_sports_count})`,
  //     tabHighlightId: "BLM-highlight",
  //   },
  // ];
  return (
    <>
      <PageHeader
        title={Sports?.find((s) => s.id == sportId)?.name}
        showBack={true}
      />
      {/* <TabMenu
        tabs={tabs}
        active_tab={active_tab}
        set_active_tab={set_active_tab}
      /> */}

      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="BLM-tabs-content">
            {/* {active_tab == "LiveNow" && <SportsDetailsLive />} */}
            {/* {active_tab == "TopLeagues" && <SportsDetailsTopLeague    toHideFilter={true} />} */}
            {/* {active_tab == "All" && <SportsDetailsAllCountries />} */}
            <div className="BLM-categoryList SB-has-viewMore">
              <div>
                {loadingMessage ? (
                  <MessageBox data={loadingMessage} />
                ) : Locations?.length ? (
                  <>
                    {_.sortBy(Locations, "name")?.map((location, index) => {
                      if (location.tournaments?.length) {
                        return (
                          <div
                            className={`BLM-accordion BLM-innerAccordion collapsed`}
                            // onClick={() => {
                            //   set_location_collapsed(!location_collapsed);
                            // }}
                            key={index}
                          >
                            <div
                              className="BLM-categoryListItem BLM-accordion-header BLM-arrowAfter"
                              id={`Location_${location?._id}`}
                              onClick={() => {
                                window.getAccordianLeagues(
                                  `Location_${location?._id}`
                                );
                              }}
                            >
                              <div className>
                                <span
                                  // className={`BLM-flag flag-${getFlagEmoji(
                                  className={`fi fi-${getFlagEmoji(
                                    location?.name
                                  )}`}
                                />
                                {location?.name}
                              </div>
                            </div>
                            <div className="BLM-accordion-content">
                              {location?.tournaments?.map((league) => {
                                return (
                                  <div
                                    className="BLM-categoryListItem"
                                    onClick={(event) => {
                                      document
                                        .getElementById("sidebar_trigger")
                                        .click();
                                      getleagueInfo(league);
                                    }}
                                  >
                                    {league?.name}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                ) : null}
              </div>
              {/* <div
                className="SB-viewMore"
                onClick={() => {
                  window.showMoreToggle("accrd-SideMenu-AllCountries");
                }}
              >
                <div className="SB-bgFade" />
                <div className="SB-viewButton" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SportsDetails;
