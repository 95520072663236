import React, { useEffect } from "react";
import PageHeader from "../../components/Common/PageHeader";
import { setActiveFooterTab } from "../../store/actions";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, isNull } from "lodash";
import startCase from "lodash/startCase";
import {currency} from "../../helpers/constants";

function SettledBets(props) {
  const dispatch = useDispatch();
  const { myBets } = props;

  useEffect(() => {
    dispatch(setActiveFooterTab("my-bets"));
  }, []);

  const getBetsCount = (mb) => {
    if (isEmpty(mb)) return "Single";
    if (mb.length == 1) return "Single";
    return "Multiple";
  };

  const _renderOddName = (f, odd) => {
    if (odd?.player) {
      return odd?.player?.name;
    }
    let text = odd?.market_spec?.name ?? "";
    text = text.replaceAll("{$competitor1}", (Array.isArray(f?.competitors) ? f?.competitors[0]?.name : ''));
    text = text.replaceAll("{$competitor2}", (Array.isArray(f?.competitors) ? f?.competitors[1]?.name : ''));
    if(text == 'u' || text == 'o') {
      text = startCase(text) +' '+ (odd?.special_bet_value ? odd?.special_bet_value : '');
    }
    else if(text.includes("{total}")){
      text = startCase(text);
      text = text.replaceAll("Total", odd?.special_bet_value);
    }
    else {
      text = startCase(text);
    }
    return text;
  };

  return (

      <>
        <div className="BLM-tab-pane active" id="BLM-settledBets">
          <div id="getSettledBets">
            {myBets.map((mb, index) => (
                <div className="BLM-betBox-group" key={index}>
                  <div
                      className="BLM-betBox BLM-accordion collapsed"
                      id={`getSettledBetAccordianId_${index}`}
                      onClick={() => {
                        $(`#getSettledBetAccordianId_${index}`).toggleClass(
                            "collapsed"
                        );
                      }}
                  >
                    <div
                        className="BLM-betBox-header BLM-accordion-header BLM-arrowBefore"
                        onclick="settleBetsCollapseAccordian(0)"
                    >
                      <div className="BLM-betBox-headerContainer">
                        <div className="BLM-contentBlock">
                          <div className="BLM-leftContent">
                            {`${getBetsCount(mb?.bets ?? [])} - ID: ${mb?.id}`}
                            <div className="BLM-stake">{`Stake: ${currency} ${
                                mb?.stake ?? mb.amount
                            }`}</div>
                            {/* <div className="BLM-stake">{`Stake Tax: ${currency} ${
                          mb?.stake_after_tax ?? mb.amount
                        }`}</div> */}

                            <div className="BLM-stake">{`Excise Tax: ${
                                mb?.excise_tax ?? mb.amount
                            }`}</div>
                            <div className="BLM-toWin">

                              {mb.turbo_gametype_id ? (
                                  ""
                              ) : (
                                  mb.game ? "" : `Actual Stake: ${currency} ${mb?.stake_after_tax ?? mb.amount}`
                              )}

                            </div>
                          </div>
                          <div className="BLM-rightContent BLM-hasBetStatus">
                            <div>
                              <div className="BLM-rightContent">
                                <div className="BLM-rightContent">
                                  {new Date(
                                      Date.parse(mb?.created_at)
                                  ).toLocaleDateString()}{" "}
                                  {new Date(
                                      Date.parse(mb?.created_at)
                                  ).toLocaleTimeString()}
                                </div>
                                <div className="BLM-stake">{`WHT: ${
                                    mb?.amount ??  parseFloat(mb.wht).toFixed(2)
                                }`}
                                </div>
                                <div className="BLM-toWin">
                                  {mb.turbo_gametype_id ? (
                                      ""
                                  ) : (
                                      mb.game
                                          ? ""
                                          : `Total Odds: ${parseFloat(mb?.total_odds).toFixed(2) ?? parseFloat(mb.amount).toFixed(2)}`
                                  )}

                                </div>
                                <div className="BLM-toWin">
                                {`To Win: ${
                                    mb?.amount ??  parseFloat(mb.wht).toFixed(2)
                                }`}
                                </div>
                              </div>
                            </div>
                            <div id="getBetWinResult_67235228">

                              <span
                                  className={`BLM-betStatus BLM-${mb.turbo_gametype_id ? (
                                      (mb?.resultType)
                                  ) : (
                                      mb.game ? (mb?.is_won ? "won" : "lost" ) : ( mb?.status == 2 ? "lost" : (mb?.status ? "won" : "lost"))
                                  )}`}
                              >
                            {mb.turbo_gametype_id ? (
                                (mb?.resultType)
                            ) : (
                                `${mb.game ? (mb?.is_won ? "won" : "lost" ) : ( mb?.status == 2 ? "Void" : (mb?.status ? "won" : "lost"))}`
                            )}

                          </span>

                              {/*                  <span*/}
                              {/*                      className={`BLM-betStatus BLM-${*/}
                              {/*                          mb.game ? (mb?.is_won ? "won" : "lost" ) : ( mb?.status == 2 ? "lost" : (mb?.status ? "won" : "lost"))*/}
                              {/*                      }`}*/}
                              {/*                  >*/}
                              {/*{`${mb.game ? (mb?.is_won ? "won" : "lost" ) : ( mb?.status == 2 ? "Void" : (mb?.status ? "won" : "lost"))}`}*/}
                              {/*  </span>*/}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {mb?.bets?.map((bet, i) => (
                        <div className="BLM-accordion-content" key={i}>
                          <div className="BLM-betBox-content">
                            <div className="BLM-bet-details">
                              <div className="BLM-betSlip-outcomeOdds">
                                <div className="BLM-betSlip-outcome">
                                  {_renderOddName(
                                      bet?.odd?.fixture_market?.fixture,
                                      bet?.odd
                                  )}
                                </div>
                                <div className="BLM-betSlip-odds">
                                  {parseFloat(bet?.price).toFixed(2)}
                                  <span
                                      className={`BLM-betStatus BLM-${
                                          (bet?.is_cancel_by_admin) ? "lost" :
                                              (bet?.status) ? "won" : ((bet?.out_come_for_canceled == 'N' || bet?.out_come_for_canceled == 'C') ? "lost" : ((parseFloat(bet?.void_factor) != parseFloat(0)) ? "lost" :  ((bet?.is_won) ? "won" : "lost")))
                                      }`}
                                  >
                              {   (bet?.is_cancel_by_admin) ? "Void" :
                                  (bet?.status) ? "Won" : ((bet?.out_come_for_canceled == 'N' || bet?.out_come_for_canceled == 'C') ? "Cancel" : ((parseFloat(bet?.void_factor) != parseFloat(0)) ? "Void" : ((bet?.is_won) ? "Won" : "Lost")))}

                            </span>
                                </div>
                              </div>
                              <div className="BLM-betSlip-market">
                                {bet?.odd?.fixture_market?.market?.name}
                              </div>
                              <div className="BLM-betSlip-fixture">
                                { Array.isArray(bet?.odd?.fixture_market?.fixture?.competitors) ?
                                    (bet?.odd?.fixture_market?.fixture?.competitors[0]
                                        .name ?? "-") : "-"
                                }{" "}
                                vs{" "}
                                {Array.isArray(bet?.odd?.fixture_market?.fixture?.competitors) ?
                                    (bet?.odd?.fixture_market?.fixture?.competitors[1]
                                        ?.name ?? "-") : "-"
                                }{" "}
                                @{" "}
                                <span className="BLM-myBets-dateandtime">
                            {new Date(
                                parseInt(
                                    bet?.odd?.fixture_market?.fixture?.match_date?.$date?.$numberLong
                                )
                            ).toLocaleString()}
                          </span>
                              </div>
                              <div className="BLM-betSlip-fixture-league">
                                {bet?.odd?.fixture_market?.fixture?.tournament?.name}
                              </div>
                            </div>
                          </div>
                          <div className="BLM-divider"></div>

                          {/* <div className="BLM-betBox-stakeRetuns">
                      <div className="BLM-contentBlock">
                        <div className="BLM-leftContent">Stake: ${currency} 9.3</div>
                        <div className="BLM-rightContent">
                          To Win: ${currency} 13.68
                        </div>
                      </div>
                    </div> */}
                        </div>
                    ))}
                    {mb?.game ? (
                        <div className="BLM-accordion-content">
                          <div className="BLM-betBox-content">
                            <div className="BLM-bet-details">
                              <div className="BLM-betSlip-outcomeOdds">
                                <div className="BLM-betSlip-outcome">
                                  {mb?.game?.game_name}
                                </div>
                                <div className="BLM-betSlip-odds">
                                  <span>{parseFloat(mb?.won_amount | [isNull], 0).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="BLM-betBox-stakeRetuns"></div>
                        </div>
                    ) : null}
                  </div>
                </div>
            ))}
          </div>
        </div>
      </>
  );
}

export default withRouter(SettledBets);
