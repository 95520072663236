import React, { useState, useEffect } from "react";
import TabMenu from "../../components/Common/TabMenu";
import { useSelector, useDispatch } from "react-redux";
import { setActiveFooterTab, setActiveTab } from "../../store/actions";
import PageHeader from "../../components/Common/PageHeader";
import MessageBox from "../../components/Common/MessageBox";
import AffiliateLink from "./AffiliateLink";
import AffiliateEarning from "./AffiliateEarning";
import {getRegulatoryAndParameters} from "../../helpers/repository";


function RefereAFriend(props) {
    const dispatch = useDispatch();
    const active_tab = useSelector(
        (state) => state?.CommonState?.activeTab ? ((state?.CommonState?.activeTab=="home-upcoming" || state?.CommonState?.activeTab=="refer-a-friend") ? "Affiliate" : state?.CommonState?.activeTab) : "Affiliate"
    );
    const [isRafActive, setIsRafActive] = useState(0);
    const isLoggedIn = sessionStorage.getItem("token") ? true : false;
    useEffect(() => {
        getRegulatoryAndParameters(handleSuccess);
    }, [isLoggedIn]);

    const handleSuccess = (res) => {
        setIsRafActive(res?.betting_limits?.is_raf_active);
    };
    const tabs = [
        {
            id: "Affiliate",
            title: "Affiliate Link",
            activeClass: active_tab == "Affiliate" ? "active" : "",
            tabHighlightText: "",
            tabHighlightId: "",
        },
        {
            id: "Earning",
            title: "Affiliate Earning",
            activeClass: active_tab == "Earning" ? "active" : "",
            tabHighlightText: ``,
            tabHighlightId: "",
        },
    ];
    return (
        (isRafActive) ?
        <>
            <PageHeader title="Refer-A-Friend/Affiliate Program" showBack={false} />
            <TabMenu
                tabs={tabs}
                active_tab={active_tab}
                set_active_tab={setActiveTab}
                dispatch={dispatch}
            />
            <div className="BLM-commonPage">
                <div className="BLM-commonPage-content-full-width BLM-common">
                    <div className="BLM-tabs-content">
                        {active_tab == "Affiliate" &&

                        <AffiliateLink/>
                        }
                        {active_tab == "Earning" &&

                        <AffiliateEarning/>
                        }
                    </div>
                </div>
            </div>

        </> : ''
    )
}

export default RefereAFriend;