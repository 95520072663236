import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PageHeader from "../../components/Common/PageHeader";
import {axiosApi, forgotPasswordCall} from "../../helpers/repository";
import { useHistory } from "react-router-dom";
import { showMessage } from "../../components/Common/Toaster";
import { setActiveTab } from "../../store/actions";
import FieldValidation from "../../components/Common/FieldValidation";

function ForgotPin(props) {
  const dispatch = useDispatch();
  const [pin, setPin] = useState(null)
  const [code, set_code] = useState(null);
  const [sendCodeMessage, setSendCodeMessage] = useState("Send Code")
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 2);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  $("#userResetMobile").on("input", function() {

    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "")
    }
    if (/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi.test(this.value)) {
      this.value = this.value.replace(/[`~a-zA-Z!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    }
  })
  useEffect(() => {
    dispatch(setActiveTab(""));
  }, []);

  let history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState("");

  const _handleForgotPassword = () => {
    let data = {};
    data.phone_number = phoneNumber;
    data.pin = pin;
    data.code = parseInt(code);
    forgotPasswordCall(data, success, error);
  };

  const success = (response) => {
    history.push("/login");
    showMessage("success", response?.data?.message);
  };
  const error = (error) => {
    if(error?.response?.data?.errors?.phone_number){
      showMessage("error", error?.response?.data?.errors?.phone_number[0]);
    }else if(error?.response?.data?.errors?.code){
      showMessage("error", error?.response?.data?.errors?.code[0]);
    }else if(error?.response?.data?.errors?.pin){
      showMessage("error", "Verification code doesn't match");
    }
    else{
      showMessage("error", error?.response?.data?.errors);
    }

  };
  const sendCode = () => {
    setSendCodeMessage("Sent");
    setRunTimer((t) => !t)
    const data = {
      phone_number: phoneNumber,
      type:'forgotpin',
    };

    axiosApi({
      method: "post",
      url: `/client/verify-code`,
      data: data
    })
        .then(function (response) {
          // console.log(response?.data?.pin)
          setPin(response?.data?.pin)
          setSendCodeMessage("Send Code")
        })
        .catch(function (response) {
          showMessage("error",response?.response?.data?.error)
        })
  }

  return (
      <>
        <PageHeader title="Forgot Pin" showBack={false} />
        <div className="BLM-commonPage">
          <div className="BLM-commonPage-content BLM-common">
            <div className="BLM-loginRegister BLM-forgotPIN">
              <div className="BLM-content">
                <div id="resetPinSuccessMessageDisplay" />
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Enter Mobile Number:</div>
                  <div className="BLM-form-control-group">
                    <div className="BLM-form-addon">+251</div>
                    <div className="BLM-form-control">
                      <input
                          type="text"
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value.replace(/\D/g, ""));
                          }}
                          id="userResetMobile"
                          placeholder="XXXXXXXXX"
                          maxLength={9}
                          required
                      />
                    </div>
                    {
                      runTimer ?
                          <div className="input-group-append">

                            <span className="input-group-text text-prepend-box"><span className="spinner-grow spinner-grow-sm"></span></span>
                          </div>
                          :
                          <div className="input-group-append" onClick={() => {
                            sendCode()
                          }}>
                            <span className="input-group-text text-prepend-box">Send Code</span>
                          </div>
                    }
                  </div>
                  <div className="BLM-form-errorMsg">
                    <span id="showResetErrorMessage" />
                  </div>
                </div>
                <div className="BLM-form-group">
                  <div className="BLM-form-label">Verification Code:</div>
                  <div
                      className="BLM-form-control BLM-form-control-pin"
                      id="regPinErrorMessageClass"
                  >
                    <input
                        type="text"
                        id="code"
                        maxLength={6}
                        required
                        onChange={(e) => {
                          set_code(e.target.value);
                        }}
                        value={code}
                    />
                  </div>
                </div>

                <div className="space-5" />
                <button
                    className="btn BLM-btnSecondary active BLM-btnLarge"
                    id="disableForgotPinButtonClick"
                    onClick={() => {
                      _handleForgotPassword();
                    }}
                >
                  <span className="BLM-btnTxt">RESET PIN</span>
                </button>
                <div className="space-10" />
              </div>
            </div>
          </div>
        </div>
      </>
  );
}

export default ForgotPin;
